<template>
  <!-- <div>hello</div> -->
  <router-view></router-view>
</template>

<script>
export default {
  setup() {},
};
</script>

<style>
*::-webkit-scrollbar {
  display: none;
}
#order-drugs-form {
  box-shadow: 0px 9px 25px 5px rgba(19, 19, 19, 0.05);
  max-width: 900px;
  margin: auto;
  padding-top: 30px;
  margin-top: 50px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: calc(100vh - 200px);
  width: 100%;
  /* overflow: hidden; */
  /* margin-top: 60px; */
}
.tele_grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.tele-button-primary {
  background: #4c70d6;
  outline: none;
  padding: 13px 50px;
  border: none;
  color: white;
  text-transform: uppercase;
  margin-left: 20px;
}

button {
  cursor: pointer;
}

.tele-button-secondary {
  color: #4c70d6;
  outline: none;
  padding: 13px 50px;
  border: none;
  background: white;
  text-transform: uppercase;
  margin-left: 20px;
  border: 1px solid #4c70d6;
}
h1 {
  margin-top: 30px;
}
.text-center {
  text-align: center;
}
.container {
  width: 80%;
  margin: auto;
}
#controls {
  margin-top: 50px;
  box-shadow: 0px -2px 5px 1px rgba(19, 19, 19, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 25px 10%;
}

.personal-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 64px;
}
.previous-holder {
  display: flex;
  align-items: center;
  gap: 20px;
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
}
.no-border {
  border: none !important;
}
.color-black {
  color: black;
}
.container-blank {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #efefef;
}

.container-blank .container {
  background: #ffffff;
  box-shadow: 0px 9px 25px 5px rgba(19, 19, 19, 0.05);
  border-radius: 10px;
  margin: 10%;
  height: calc(100vh - 20%);
  width: 80%;
  overflow: scroll;
  /* padding: 25px; */
}

.container-blank .container::before {
  content: "";
  background-image: url("./assets/top-corner.png");
  background-position: top right;
  background-repeat: no-repeat;
  height: 300px;
  width: 300px;
  margin: -10%;
  position: absolute;
}

.container-blank .container::after {
  content: "";
  background-image: url("./assets/pokka.png");
  background-position: right;
  background-repeat: no-repeat;
  height: 300px;
  width: 300px;
  margin-left: 50%;
  margin-top: -10%;
  position: fixed;
}

.container-blank .content {
  position: relative;
  padding: 40px;
  height: 100%;
  width: 80%;
  margin: auto;
}
.content .controls {
  margin-top: 70px;
}
body {
  margin: 0;
}
a {
  color: #61656a;
  text-decoration: none;
}
.controls-footer {
  /* position: absolute; */
  margin-top: 100px;
  bottom: 150px;
  width: 100%;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-25 {
  margin-bottom: 25px;
}
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-10 {
  gap: 10px;
}

.gap-50 {
  gap: 50px;
}

.blue-color {
  color: #4b70d6;
}
.disabled {
  background: #e6e6e6;
  color: #b3b3b3;
  cursor: not-allowed;
}
.justify-between {
  justify-content: space-between;
}
.w-full {
  width: 100%;
}
.bg-gray {
  background: #f5f5f5;
}

._delete {
  color: #ff0000;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.list {
  padding: 10px;
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  border-radius: 4px;
}

.list:hover {
  background: #f9f9fc;
}

.list input {
  margin-right: 30px;
}

.all_buttons {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.all_buttons button {
  font-size: 12px;
  padding: 10px;
  margin: 0;
  border-radius: 4px;
}

.list-container {
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  /* justify-content: center; */
  gap: 10px;
  flex-wrap: wrap;
}
.vue-select {
  height: 59px;
  line-height: 59px;
  /* padding: 17px; */
  background: #f6f6f6;
  width: 100% !important;
}

.vue-select input {
  background: #f6f6f6;
  padding: 10px;
  height: 30px;
}

.vue-dropdown-item {
  list-style-type: none;
  padding: 4px;
  cursor: pointer;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
}
button.__hide {
  border: 1px solid gray;
  border-radius: 4px;
}

@media screen and (max-width: 950px) {
  .personal-info {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .flex {
    flex-direction: column;
  }
  #order-drugs-form .flex {
    display: flex !important;
    flex-direction: row !important;
  }
  .__arcordion._delete {
    border: 1px solid #ff0000;
  }
  #order-drugs-form {
    margin: 0;
  }
  .p-3 {
    padding: 0 20px !important;
  }
  .container {
    width: 96%;
  }
  .tele-button-secondary {
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  #controls {
    padding: 10px;
  }
  .tele-button-primary {
    padding: 10px 20px;
  }
  .previous-holder {
    font-size: 14px;
  }
  .tele_grid._table {
    grid-template-columns: repeat(7, 100px);
    overflow: scroll;
  }
  .blank {
    display: none !important;
  }
  .container-blank .content {
    height: 80%;
    padding: 10px;
  }
  #app {
    height: 100%;
    width: 100%;
    /* overflow: hidden; */
    /* margin-top: 60px; */
  }
  .container-blank .container::before {
    display: none;
  }
  .container-blank .container {
    margin: 10px auto !important;
    border-radius: 10px;
    margin: 10%;
    height: calc(100vh - 6%);
    width: 91%;
  }
  .container-blank button {
    margin: 10px;
  }
}
</style>
