import { createWebHistory, createRouter } from "vue-router";
// import Home from "@/pages/home-page.vue";
import OrderDrugs from "@/pages/order-drug.vue";
import Login from "@/pages/login-page.vue";
import SignUp from "@/pages/sign-up-page.vue";
import Verify from "@/pages/verify-page.vue";
import ForgetPassword from "@/pages/forget-password/index-page.vue";
import Home from "@/pages/home-page.vue";
import { Auth } from "@/configs/request-handler";

const routes = [
  {
    path: "/order-drugs",
    name: "OrderDrugs",
    component: OrderDrugs,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/login",
    name: "Login Page",
    component: Login,
  },
  {
    path: "/forget-password",
    name: "Forget Password Page",
    component: ForgetPassword,
  },
  {
    path: "/verify-code",
    name: "Forget Password Verify Code Page",
    component: Verify,
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
  {
    path: "/",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (Auth.currentUser()) {
      next();
    } else {
      next({ path: "/login" });
    }
  } else {
    if (Auth.currentUser()) {
      console.log("User is authenticated");
      if (to.path === "/login") {
        next({
          name: "OrderDrugs",
        });
      }
    }
  }
  next();
});

export default router;
