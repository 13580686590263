import { createStore } from "vuex";
import { useToast } from "vue-toastification";

const toast = useToast();

const logSetter = (payload) => {
  console.table(payload);
};

export default createStore({
  state: {
    allPatients: [],
    duplicatePatients: [],
    user: {
      isAuthenticated: false,
    },
    patient: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      patient_id: "",
      address_1: "",
      state: "",
      city: "",
      // lga
    },
    products: {
      prescription: [],
      estimated_delivery: "",
      practional_phone_number: "",
      practinonal_last_name: "",
      practinonal_first_name: "",
      instruction: "",
    },
    patientsPagination: {
      next: true,
      previous: false,
    },
  },
  getters: {},
  mutations: {
    setAllPatients(state, payload) {
      state.patientsPagination.next = !!payload.next;
      state.patientsPagination.previous = !!payload.previous;
      state.allPatients = payload.results;
      state.duplicatePatients = payload.results;
    },
    toast(_, payload) {
      toast[payload.type](payload.message);
    },
    patientData(state, payload) {
      state.patient = payload;
    },
    setProducts(state, payload) {
      state.products.prescription = payload;
      logSetter(payload);
    },
    setProductsMeta(state, payload) {
      state.products = {
        ...state.products,
        ...payload,
      };
      logSetter(payload);
    },
    deleteIndex(state, payload) {
      state.products.prescription.splice(payload, 1);
    },
    addProducts(state, payload) {
      console.log(payload, "payload");
      state.products.prescription = state.products.prescription.concat(payload);
    },
    searchPatient(state, payload) {
      if (!payload.length) {
        state.allPatients = state.duplicatePatients;
      }
      state.allPatients = state.duplicatePatients.filter((e) => {
        if (
          e.first_name.match(payload, "gi") ||
          e.last_name.match(payload, "gi") ||
          e.email.match(payload, "gi") ||
          e.phone_number.match(payload, "gi")
        ) {
          return e;
        }
      });
    },
  },
  actions: {},
  modules: {},
});
