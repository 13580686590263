<template>
  <section class="container-blank">
    <blank-side src="/img/Frame-verify.png" />
    <div class="container">
      <div class="content">
        <h3>Let’s verify your mail</h3>
        <p>
          Please enter the verification code sent to
          <br />
          <b> {{ $route.params.phone_no }}</b>
        </p>
        <div class="card-holder">
          <div class="tele-input" v-for="i in 6" :key="i">
            <input @input="getValue($event, i)" />
          </div>
        </div>
        <p class="text-center controls blue-color" @click="resendCode()">
          Resend Code
        </p>
        <div class="text-center controls">
          <tele-button label="verify" @click="submitCode()" />
        </div>

        <div class="controls-footer">
          <div class="flex align-center gap-10" @click="$router.push('/login')">
            <img src="../assets/arrow-left.png" /> Back to login
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeleButton from "@/sub-components/tele-button.vue";
import BlankSide from "@/components/blank-side.vue";
import { requestHandler } from "@/configs/request-handler";
import store from "@/store";

export default {
  components: { TeleButton, BlankSide },
  data() {
    return {
      otp: [],
      disabled: false,
    };
  },
  methods: {
    getValue(event, i) {
      this.otp.push(event.target.value);
      if (this.otp.length === 6) {
        this.submitCode();
      } else {
        document.querySelectorAll("input")[i].focus();
      }
    },
    async resendCode() {
      this.disabled = true;
      try {
        const response = await requestHandler().post("/resend-otp/", {
          old_phone: this.$route.params.phone_no,
          new_phone: this.$route.params.phone_no,
        });
        console.log(response);
      } catch (error) {
        console.log(error);
        store.commit("toast", {
          type: "error",
          message: error.response?.data?.message || "Something went wrong",
        });
      }
      this.disabled = false;
    },
    async submitCode() {
      this.disabled = true;
      try {
        if (this.otp.length === 5) {
          const response = await requestHandler().post("/verify/", {
            phone_no: this.$route.params.phone_no,
            otp: this.otp.join(""),
          });
          if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            this.$router.push("/order-drugs");
          }
        }
        this.$router.push("/login");
      } catch (error) {
        store.commit("toast", {
          type: "error",
          message: error.response?.data?.message || "Something went wrong",
        });
      }
      this.disabled = false;
    },
  },
};
</script>

<style>
.card-holder {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  width: 60%;
  margin: 0;
}

.card-holder input {
  padding: 20px;
  width: 25px;
  font-size: 25px;
}
</style>
