<template>
  <div class="container">
    <h1 class="text-center">Product Information</h1>
    <section class="tab-container">
      <!-- <div>
        <button
          class="tab"
          @click="currentTab = 1"
          :class="currentTab === 1 ? 'border-buttom' : ''"
          type="button"
        >
          Bulk Uploads
        </button>
        <button
          class="tab"
          @click="currentTab = 0"
          :class="currentTab === 0 ? 'border-buttom' : ''"
          type="button"
        >
          Products
        </button>
      </div> -->

      <!-- <div>
        <span><img src="../assets/info.svg" /> </span>
      </div> -->
      <select-input></select-input>
    </section>
    <div
      v-show="currentTab === 0"
      v-for="(product, i) in products"
      :key="i"
      class="p-3 m-5"
    >
      <div class="flex justify-between align-center w-full mb-15">
        <p v-if="!product.show">
          {{ product.product_name.name || product.other_name }}
        </p>
        <p v-else></p>
        <div class="flex gap-10">
          <button
            @click="
              products.length > 1
                ? (product.show = !product.show)
                : (product.show = true)
            "
            class="__arcordion"
            type="button"
            v-if="!product.show"
          >
            <img src="/img/edit_2.png" />
            Edit
          </button>

          <button
            @click="
              products.length > 1 && !disabled
                ? (product.show = !product.show)
                : (product.show = true)
            "
            class="__arcordion __hide"
            type="button"
            v-if="product.show"
          >
            Hide
          </button>

          <button
            @click="deleteProduct(i)"
            class="__arcordion _delete"
            type="button"
          >
            <img src="/img/delete_1.png" />
            Delete
          </button>
        </div>
      </div>
      <div v-if="product.show" class="mt-15">
        <div class="personal-info">
          <div class="tele-input">
            <label style="margin-bottom: 10px"> Product Name </label>
            <vue-select
              v-model="product.product_name"
              label-by="name"
              @search:input="handleInput($event, i)"
              searchable
              :options="productNames"
              :loading="loading"
              :close-on-select="true"
              @selected="handleSelected($event, i)"
              :value="product.product_name.name"
            ></vue-select>
          </div>

          <div class="tele-input">
            <label style="margin-bottom: 10px"> Product Category </label>
            <vue-select
              v-model="product.product_category"
              label="Product Category"
              :placeholder="
                product.product_category || 'Select Product Category'
              "
              :options="productCategoryOptions"
            ></vue-select>
          </div>
        </div>
        <section>
          <div>
            Packs
            <div class="tele_pack">
              <button
                type="button"
                @click="product.pack > 0 && product.pack--"
                :disabled="product.pack < 1"
              >
                -
              </button>
              <div>{{ product.pack }}</div>
              <button type="button" @click="product.pack++">+</button>
            </div>
          </div>
          <p>Define the product prescriptions for the patient.</p>
          <div class="personal-info">
            <div class="tele-input">
              <label> Strength </label>
              <input placeholder="e.g 500mg" v-model="product.take" required />
            </div>
            <div class="tele-input">
              <label> Frequency </label>
              <input
                placeholder="1"
                v-model="product.dosage"
                required
                type="number"
                min="1"
              />
            </div>
            <div class="tele-input">
              <label> Duration </label>
              <input
                placeholder="in Days"
                v-model="product.duration"
                required
                type="number"
                min="1"
              />
            </div>
            <div class="tele-input">
              <label> Comments / Instructions (optional) </label>
              <input v-model="product.instruction" rows="2" />
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="flex-add-container" v-if="currentTab === 0">
      <tele-button
        class-name="tele-button-secondary m-5"
        label="+ Add Product"
        @click="addProduct()"
        :disabled="disabled"
      >
      </tele-button>
    </div>
    <div v-if="currentTab === 1">
      <bulk-upload />
    </div>

    <div class="p-3 m-5">
      <hr />
      <schedule-information />
    </div>
  </div>
</template>

<script>
import TeleButton from "@/sub-components/tele-button";
import ScheduleInformation from "@/components/schedule-information";
import BulkUpload from "@/components/bulk-upload";
import store from "@/store";
import { requestHandler } from "@/configs/request-handler";
import VueSelect from "vue-next-select";
import  SelectInput  from "@/components/select-item";

export default {
  name: "product-information",
  components: {
    BulkUpload,
    ScheduleInformation,
    TeleButton,
    VueSelect,
    SelectInput
  },
  data() {
    return {
      tab: ["single", "bulk"],
      productCategoryOptions: ["3", "4"],
      currentTab: 0,
      productNames: [],
      loading: false,
      products: [
        {
          product_name: {},
          other_name: "",
          product_category: "",
          dosage: "",
          duration: "",
          take: "",
          show: true,
          pack: 1,
        },
      ],
      duplicateProductNames: [],
    };
  },
  methods: {
    async getProductDetails(term) {
      this.loading = true;
      requestHandler()
        .get("products/search?products=" + (term ? term : ""))
        .then((res) => {
          console.log(res);
          this.productNames = res.data.results.all;
          this.duplicateProductNames = res.data.results.all;
          this.loading = false;
        });
    },
    handleSelected(e, i) {
      this.products[i].other_name = e.name;
      // const doc = document.querySelectorAll(".vue-input input");
      // doc[i].value = e.name;
    },
    filterProductDetails(term) {
      if (term === "") {
        this.productNames = this.duplicateProductNames;
      }
      const r = new RegExp(term, "gi");
      const t = this.duplicateProductNames.filter((e) => {
        if (r.test(e.name)) {
          return e;
        }
      });

      console.log(t);
    },
    async handleInput(event, i) {
      const value = event.target.value;

      this.products[i].product_name = {};
      this.products[i].other_name = value;
      this.getProductDetails(value).then();
    },
    setProductDetails(i) {
      if (!this.products[i].product_name?.name) {
        const doc = document.querySelectorAll("#v_search_product input");
        const value = doc[i].value;
        this.products[i].product_name.name = value;
      }
    },
    async getProductCategory() {
      requestHandler()
        .get("products/category")
        .then((res) => {
          this.productCategoryOptions = res.data.results.map(
            (item) => item.name
          );
        });
    },
    addProduct() {
      this.products.forEach((e) => {
        e.show = false;
      });
      this.products.push({
        product_name: {},
        product_category: "",
        other_name: "",
        dosage: "",
        duration: "",
        take: "",
        show: true,
        pack: 1,
      });
    },
    deleteProduct(index) {
      if (this.products.length > 1) {
        this.products.slice(index, 1);
        store.commit("deleteIndex", index);
      }
    },
    placeHolder(product) {
      return product.product_name.name
        ? product.product_name.name
        : product.other_name
        ? "Search for products"
        : "";
    },
  },
  computed: {
    disabled() {
      const currentProduct = this.products[this.products.length - 1];
      if (!currentProduct) return true;
      const fields = Object.keys(currentProduct);
      let valid;
      if (fields) {
        valid = fields.find((key) => {
          if (key === "other_name") return false;
          if (this.products[this.products.length - 1][key] === "") {
            return true;
          }
        });
      }

      if (valid) return true;
      return false;
    },
  },
  mounted() {
    this.getProductCategory();
    this.getProductDetails();
  },
  watch: {
    currentTab() {
      this.products = store.state.products.prescription.length
        ? store.state.products.prescription
        : this.products;
      console.log("changed", store.state.products.prescription.length);
    },
    products: {
      handler() {
        store.commit("setProducts", this.products);
      },
      deep: true,
    },
  },
};
</script>

<style>
.tele_pack {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 20px;
}
.tele_pack button {
  height: 40px;
  width: 40px;
  border: none;
}
.tele_pack button:disabled {
  cursor: not-allowed;
}
.tele_pack div {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
  color: #000;
}
.tab {
}
.flex-add-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: red;
}
.tab-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.tab {
  background: transparent;
  border: none;
  padding: 10px;
  margin-right: 20px;
  font-style: 20px;
}

.border-buttom {
  border-bottom: 2px solid blue;
}
.personal-info {
  margin-bottom: 15px;
}
.p-3 {
  padding: 30px;
}
.m-5 {
  margin: 20px 0;
}
</style>
