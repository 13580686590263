<template>
  <div class="larger-view">
    <!-- larger view ports -->
    <div class="overlay-nav" v-if="closeNav">
      <button class="close-btn" @click="closeNav = false">×</button>
      <h3>How It Works</h3>
      <!-- <router-link to="/login" @click="closeNav = false">
        <button class="button">Login</button>
      </router-link> -->
      <form action="https://docs.google.com/forms/d/e/1FAIpQLSciAmOf3vF53UjI84N8-dteEDd98r2VIzQCml29FSem_gTd8w/viewform?usp=sf_link"  target="_blank">
        <button  class="button" type="submit">Get started </button>
      </form>
    </div>
    <header class="header">
      <img src="/img/Logo.png" alt="logo image" />
      <a href="#works" style="font-weight: bold">How it works</a>
      <form action="https://docs.google.com/forms/d/e/1FAIpQLSciAmOf3vF53UjI84N8-dteEDd98r2VIzQCml29FSem_gTd8w/viewform?usp=sf_link"  target="_blank">
        <button  class="button" type="submit">Get started </button>
      </form>
      <img
          @click="closeNav = true"
          class="mobile-menu"
          src="/mobile-image/menu .png"
          alt="mobile-menu"
      />
    </header>

    <div class="banner-container">
      <div class="banner">
        <div class="desc">
          <p class="desc-top">
            <span> Now you can give your online patients </span>
            <span class="_blue_banner"> complete healthcare. </span>
          </p>
          <p class="desc-bottom no-mobile">
            Access enables Licensed Healthcare Providers to process <br />
            and deliver online prescriptions faster. Your patients will
            <br />never have to wait for their medications again!
            <br />
            <br />
          </p>
          <p class="desc-bottom only-mobile">
            Access enables Licensed Healthcare Providers to process and deliver
            online prescriptions faster. Your patients will never have to wait
            for their medications again!
          </p>
          <div class="btn-holder_">
            <form action="https://docs.google.com/forms/d/e/1FAIpQLSciAmOf3vF53UjI84N8-dteEDd98r2VIzQCml29FSem_gTd8w/viewform?usp=sf_link"  target="_blank">
              <button  class="button" type="submit">Get started </button>
            </form>
          </div>
        </div>
        <div class="banner_image">
          <img src="/img/desktop-banner.png" />
        </div>
      </div>
    </div>

    <div class="experience">
      <div class="experience-desc">
        <div class="experience-descs">
          <img src="/img/support.svg" alt="convenient care" />
          <h3>24/7 Dedicated Support</h3>
          <p>
            Round the clock customer support across all channels for you and
            your patients.
          </p>
        </div>
        <div class="experience-descs">
          <img src="/img/Quality-services.png" alt="quality services" />
          <h3>Last mile expertise</h3>
          <p>
            Direct delivery of pharmaceuticals to patients with zero hassles or
            complaints.
          </p>
        </div>
        <div class="experience-descs">
          <img src="/img/Quick-care.png" alt="quick care" />
          <h3>Faster Turn Around Time!</h3>
          <p>
            We know your patients need their medications, so we never keep them
            waiting.
          </p>
        </div>
      </div>
    </div>

    <div class="img-before"></div>

    <div class="banner-2-color">
      <div class="img-after"></div>

      <div class="howItWorks" id="works">
        <h3><i>Access</i> by DrugStoc is easy to use!</h3>
        <p class="text-center">
          For healthcare professionals practising digitally and on telehealth
          platforms, Access offers an easier solution for your pharmaceutical
          procurement and last-mile delivery needs.
        </p>
      </div>
      <div class="how-it-works-image">
        <div class="text-center">
          <h3>How it Works</h3>
          <img src="/img/squikly-line.png" class="squikly-line" />
        </div>

        <div class="flex-how-it-works">
          <div>
            <ul class="how-it-works-step">
              <li>
                Access is the first prescription management platform specially
                built for telehealth providers in Nigeria. Access improves
                digital healthcare and supports telemedicine practice in
                Nigeria.
              </li>
              <li>
                We have an impressive network of over 1400 registered pharmacies
                ready to attend to your patients' prescriptions immediately you
                send them.
              </li>
              <li>
                Our goal is to advance digital healthcare in Africa and ensure
                that online patients consistently receive the best care without
                delay or complaints.
              </li>
            </ul>
          </div>
          <img src="/img/introduction.png" alt="how it works" />
        </div>
      </div>
    </div>
    <div class="last-p">
      <h3 class="mb-25">Join Other Pharmacies Advancing With Telemedicine.</h3>
      <p class="text-center join-text">
        Want to boost daily sales and deliver online prescriptions?
      </p>
      <form action="https://docs.google.com/forms/d/e/1FAIpQLSciAmOf3vF53UjI84N8-dteEDd98r2VIzQCml29FSem_gTd8w/viewform?usp=sf_link"  target="_blank">
        <button  class="button reversed" type="submit">Join Here </button>
      </form>
    </div>
  </div>

  <div class="sponsor-before"></div>

  <div class="sponsor">
    <div class="sponsors">
      <h3>our partners</h3>
      <div class="sponsors-logo">
        <img src="/img/growth capital by cchub 1.png" alt="growth" />
        <img src="/img/vestedworld 1.png" alt="vested world" />
        <img
            src="/img/bill-melinda-gates-foundation.png"
            alt="bill and melinda gates"
        />
      </div>
      <div class="sponsors-logo">
        <img src="/img/alibaba-foundation.png" alt="alibaba" />
        <img src="/img/AICAafrica.png" alt="Aisle" />
        <img src="/img/sterling.png" alt="sterling" />
        <img src="/img/seedfoundation.png" alt="standard seed" />
      </div>
    </div>
  </div>

  <div class="news-letter-container">
    <div class="newsletter">
      <h3>Subscribe To Our Newsletter</h3>
      <p>
        Don't want to miss our exciting news ? Just leave us your contact
        information and we'll keep you updated !
      </p>
      <div class="subscribe-input">
        <input placeholder="Enter Email Address" type="email" />
        <button type="submit">Subscribe</button>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="footer-links">
      <div class="business-links">
        <div>
          <h3>Company</h3>
          <ul class="list-unstyled quick-links">
            <li>
              <a
                  aria-label="Navigate To About us Page"
                  href="https://drugstoc.com/about"
              >About us</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To Careers at DrugStoc"
                  href="https://drugstoc.com/careers"
              >Careers at DrugStoc</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To Contact Page"
                  href="https://drugstoc.com/contact"
              >Contact</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To Terms and Condition Page"
                  href="https://drugstoc.com/terms-and-conditions"
              >Terms and Conditions</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To Pro"
                  href="https://drugstoc.com/privacy-policy"
              >Privacy</a
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h3>Our Solutions</h3>
          <ul class="list-unstyled quick-links">
            <li>
              <a
                  aria-label="Navigate To Procure Direct page"
                  href="https://drugstoc.com/procure-direct"
              >Procure Direct</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To DrugStoc Credit page"
                  href="https://drugstoc.com/drugstoc-credit"
              >DrugStoc Credit</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To RxDelivery page"
                  href="https://drugstoc.com/rx-delivery"
              >RxDelivery</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To Market Access page"
                  href="https://drugstoc.com/market-access"
              >Market Access</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To RXInsights page"
                  href="https://drugstoc.com/rx-insight"
              >RXInsights</a
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h3>Our Clients</h3>
          <ul class="list-unstyled quick-links">
            <li>
              <a
                  aria-label="Navigate To For Pharmacy page"
                  href="https://drugstoc.com/procure-direct"
              >For Pharmacy</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To For Hospitals &amp; Clinics page"
                  href="https://drugstoc.com/procure-direct"
              >For Hospitals &amp; Clinics</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To For Licensed Medical Practitioners page"
                  href="https://drugstoc.com/procure-direct"
              >For Licensed Medical Practitioners</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To For HMOs page"
                  href="https://drugstoc.com/partnerships"
              >For HMOs</a
              >
            </li>
            <li>
              <a
                  aria-label="Navigate To For Government facilities page"
                  href="https://drugstoc.com/partnerships"
              >For Government facilities</a
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h3>Address</h3>
          <ul class="list-unstyled quick-links">
            <li>
              <a
                  href="https://goo.gl/maps/nuNGr2W267ehHXwG7"
                  aria-label="Navigate To Google maps"
                  target="_blank"
                  rel="noopener noreferrer"
              >Drugstoc Inc.</a
              >
            </li>
          </ul>
          <h5>Contact Us</h5>
          <ul class="list-unstyled quick-links">
            <li>
              <a
                  href="mailto:info@drugstoc.com"
                  aria-label="Navigate To Google maps"
                  target="_blank"
                  rel="noopener noreferrer"
              >info@drugStoc.com</a
              >
            </li>
            <li>
              <a
                  href="tel:+234(0)1 7005571"
                  aria-label="Navigate To Google maps"
                  target="_blank"
                  rel="noopener noreferrer"
              >+234(0)1 7005571</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="copyrights">
      <p>
        © 2022 DrugStoc. All Rights Reserved. Designed & Developed by Drugstoc
      </p>
      <p>Privacy Policy | Terms of Service</p>
      <div class="socials">
        <a href="https://www.instagram.com/drugstoc/" target="_blank">
          <img src="/img/Instagram.png" alt="instagram" />
        </a>
        <a href="https://twitter.com/DrugStocNg" target="_blank">
          <img src="/img/Twitter.png" alt="twitter" />
        </a>
        <a href="https://www.linkedin.com/company/drugstoc/" target="_blank">
          <img src="/img/Linkedin.png" alt="linkedIn" />
        </a>
        <a
            href="https://web.facebook.com/profile.php?id=100021606439094"
            target="_blank"
        >
          <img src="/img/Facebook.png" alt="facebook" />
        </a>
      </div>
    </div>
  </div>

  <div @click="handleClick">
    <div id="mc_embed_signup" v-if="showForm">
      <form
          action="https://drugstoc.us7.list-manage.com/subscribe/post?u=d1ad5a4e3e54d7a6740cd73e7&amp;id=7109767c2c"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
      >
        <div id="mc_embed_signup_scroll">
          <h2>Join Our Waitlist</h2>
          <div class="indicates-required">
            <span class="asterisk">*</span> indicates required
          </div>
          <div class="mc-field-group">
            <label for="mce-EMAIL"
            >Email Address <span class="asterisk">*</span>
            </label>
            <input
                type="email"
                value=""
                name="EMAIL"
                class="required email tele-input"
                id="mce-EMAIL"
            />
          </div>
          <div class="mc-field-group">
            <label for="mce-FNAME"
            >First Name <span class="asterisk">*</span>
            </label>
            <input
                type="text"
                value=""
                name="FNAME"
                class="required"
                id="mce-FNAME"
            />
          </div>
          <div class="mc-field-group">
            <label for="mce-LNAME"
            >Last Name <span class="asterisk">*</span>
            </label>
            <input
                type="text"
                value=""
                name="LNAME"
                class="required"
                id="mce-LNAME"
            />
          </div>
          <div class="mc-field-group size1of2">
            <label for="mce-PHONE"
            >Phone Number <span class="asterisk">*</span>
            </label>
            <input
                type="text"
                name="PHONE"
                class="required"
                value=""
                id="mce-PHONE"
            />
          </div>
          <div id="mce-responses" class="clear">
            <div
                class="response"
                id="mce-error-response"
                style="display: none"
            ></div>
            <div
                class="response"
                id="mce-success-response"
                style="display: none"
            ></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
                type="text"
                name="b_d1ad5a4e3e54d7a6740cd73e7_7109767c2c"
                tabindex="-1"
                value=""
            />
          </div>
          <div class="clear mt-15">
            <button type="submit" class="button">Subscribe</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      closeNav: false,
      showForm: false,
    };
  },
  methods: {
    handleClick(e) {
      // this.$router.push("/login");
      const form = document.querySelector("#mc_embed_signup");
      if (e.target.contains(form)) {
        this.showForm = false;
        document.body.style.overflow = "auto";
      } else {
        this.showForm = true;
        document.body.style.overflow = "hidden";
      }
    },
    toggleDisplayForm() {
      this.showForm = !this.showForm;
    },
  },
};
</script>

<style>
@import url("../styles/drugstoc.css");
@import url("../styles/drugstoc-smaller.css");
</style>
