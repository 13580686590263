<template>
  <div class="tele-input">
    <label :for="name">
      {{ label }}
    </label>

    <input
      :name="label"
      :type="type"
      :placeholder="placeholder"
      v-if="type !== 'select' && type !== 'datalist'"
      @change="getValue"
      :required="required !== undefined ? required : true"
    />
    <select
      v-if="type === 'select'"
      @change="$emit('input', $event.target.value || '')"
      :required="true"
    >
      <option value="" disabled :selected="value === ''">
        select {{ label.toLowerCase() }}
      </option>
      <option
        v-for="(opt, i) in option"
        :key="i"
        :value="opt"
        :selected="value === opt"
      >
        {{ opt }}
      </option>
    </select>

    <input
      list="datalist-options"
      id="datalist-options-choice"
      name="datalist-options-choice"
      v-if="type === 'datalist'"
      v-model="value"
      @change="getListValue"
    />
    <datalist v-if="type === 'datalist'" id="datalist-options" required="true">
      <option v-for="(opt, i) in option" :key="i" :value="opt">
        {{ opt }}
      </option>
    </datalist>
  </div>
</template>
<script>
export default {
  props: [
    "label",
    "name",
    "type",
    "placeholder",
    "option",
    "required",
    "initialValue",
  ],
  methods: {
    getValue($event) {
      this.$emit("input", $event?.target?.value);
    },
    getListValue($event) {
      // this.$emit("input", $event?.target?.value);
      console.log($event);
    },
  },
  data() {
    return {
      value: this.initialValue || "",
    };
  },
  watch: {
    initialValue() {
      this.value = this.initialValue;
    },
  },
};
</script>
<style>
.tele-input {
  display: grid;
}
.tele-input input,
.tele-input select,
.tele-input textarea {
  padding: 20px 10px;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #dadada;
  margin-top: 10px;
}

.tele-input textarea {
  /* min-height: 200px !important; */
}
.__arcordion {
  margin-top: 10px;
  font-size: 10px;
  width: 80px;
  padding: 10px 12px;
  border-radius: 5px;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.__arcordion {
  background: transparent;
}

.__arcordion img {
  width: 14px;
}
</style>
