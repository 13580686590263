<template>
  <section class="order-successful text-center">
    <img src="/done.png" />
    <div>
      <h2 class="mb-15">Sent Successfully!</h2>
      <!-- <h1>Response usually takes a while.</h1> -->
    </div>
    <div class="tele_done_holder mt-15">
      <!-- <tele-button
        label="Continue"
        class-name="tele-button-secondary"
        @click="$router.push('/order-drugs')"
      >
      </tele-button> -->
      <tele-button label="Done" @click="reload()"> </tele-button>
    </div>
  </section>
</template>

<sript>

</sript>
<script>
import TeleButton from "@/sub-components/tele-button";
import store from "@/store";
export default {
  components: { TeleButton },
  methods: {
    reload() {
      window.location.reload();
    },
  },
  mounted() {
    store.commit("toast", {
      message: "order sent successfully",
      type: "success",
    });
  },
};
</script>
<style scoped>
.order-successful {
  /* overflow: hidden; */
  /* position: fixed; */
  width: 100%;
  height: calc(100vh - 500px);
  display: grid;
  justify-items: center;
  align-content: center;
  padding-bottom: 75px;
}
* section,
* div {
  margin: 0;
}
</style>
