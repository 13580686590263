<template>
  <section class="container-blank">
    <blank-side src="/img/Frame-sign-up.png" />
    <form class="container" @submit.prevent="submit">
      <div class="content">
        <h1>Create Account</h1>
        <p>
          Already have an account ?
          <router-link to="/login">Sign In</router-link>
        </p>
        <div>
          <div class="personal-info mt-15">
            <tele-input
              label="Full Name"
              @input="(value) => (full_name = value)"
            />
            <tele-input
              label="Company Name"
              @input="(value) => (company_name = value)"
            />
          </div>
          <div class="personal-info mt-15">
            <tele-input
              label="Email Address"
              @input="(value) => (email = value)"
            />
            <tele-input
              label="Category"
              @input="(value) => (category = value)"
              type="select"
              :option="[
                'pharmacy',
                'doctors-office',
                'hospital',
                'patient',
                'clinic',
              ]"
            />
          </div>

          <div class="personal-info mt-15">
            <div class="tele-input">
              <label>Phone Number</label>
              <input
                v-model="phone_number"
                placeholder="23424556236"
                type="number"
              />
            </div>
            <tele-input
              label="Password"
              type="password"
              @input="(value) => (password = value)"
            />
          </div>
        </div>

        <div class="text-center controls">
          <tele-button
            label="Create Account"
            :type="submit"
            :disabled="disabled"
          />
        </div>
        <!-- <p class="text-center">or</p> -->
        <!-- <div class="controls text-center">
          <tele-button
            class="tele-button-secondary no-border color-black"
            label="Continue with Google"
          >
          </tele-button>
          <tele-button label="Continue with facebook"> </tele-button>
        </div> -->
      </div>
    </form>
  </section>
</template>

<script>
import TeleInput from "@/sub-components/tele-input";
import TeleButton from "@/sub-components/tele-button.vue";
import BlankSide from "@/components/blank-side.vue";
import { requestHandler } from "@/configs/request-handler";
import store from "@/store";

export default {
  components: {
    TeleInput,
    TeleButton,
    BlankSide,
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      full_name: "",
      email: "",
      company_name: "",
      phone_number: "",
      password: "",
      category: "",
      disabled: false,
    };
  },
  methods: {
    async submit() {
      this.disabled = true;
      try {
        if (this.phone_number.length !== 11) {
          throw new Error("Phone number must be 11 digits");
        }
        const data = {
          first_name: this.full_name.split(" ")[0],
          last_name: this.full_name.split(" ")[1] || "-",
          name: this.full_name,
          email: this.email,
          company_name: this.company_name,
          phone_no: this.phone_number,
          password: this.password,
          category: "pharmacy",
        };
        const response = await requestHandler().post("/register", data);
        console.log(response);
        store.commit("toast", {
          type: "success",
          message: response.data.message || "Successfully created account",
        });
        this.$router.push(
          "/verify-code?email=" + this.email + "&phone=" + this.phone_number
        );
      } catch (error) {
        console.log(error);
        store.commit("toast", {
          message: error.response?.data?.message || "An error occured",
          type: "error",
        });
      }
      // 044302

      this.disabled = false;
    },
  },
};
</script>
