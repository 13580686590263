<template>
  <section class="container-1/3">
    <div class="text-center">Please formate your content din this manner;</div>
    <p class="text-center">Product Name, Description, Pack</p>

    <div class="text-center">
      <div class="file-upload-container">
        <img src="/file-upload.svg" />
        <p>Upload File (Excel, CSV)</p>
        <div>
          <button
            @click="$refs.file_upload.click()"
            type="button"
            class="upload-handler"
          >
            Browse
          </button>
          <input
            type="file"
            ref="file_upload"
            class="hidden"
            @change="(e) => handleFileChange(e)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Papa from "papaparse";
import store from "@/store";

export default {
  name: "bulk-upload",
  methods: {
    handleFileChange(e) {
      console.log(e.target.files[0]);
      Papa.parse(e.target.files[0], {
        header: true,
        complete: (results) => {
          results.data.forEach((e) => {
            e.show = false;
          });
          store.commit("addProducts", results.data);
          store.commit("toast", {
            message: `${results.data.length} products added`,
            type: "success",
          });
        },
      });
    },
  },
};
</script>

<style>
.hidden {
  display: none;
}
.file-upload-container {
  border: 1px dashed #dadada;
  padding: 20px;
  border-radius: 20px;
  width: 250px;
  margin: auto;
  margin-bottom: 40px;
}
.container-1\/3 {
  width: 40%;
  margin: auto;
}
.upload-handler {
  background: #dadada;
  padding: 10px 20px;
  border: none;
  outline: none;
}
</style>
