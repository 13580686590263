<template>
  <div class="container">
    <h1>Prescription Summary</h1>
    <section class="prescription-summary">
      <div>
        <p>Patient Full Name</p>
        <p>
          <b>
            {{ getAllDetails.patient.first_name }}
            {{ getAllDetails.patient.last_name }}
          </b>
        </p>
      </div>
      <div>
        <p>Email Address</p>
        <p>
          <b>{{ getAllDetails.patient.email }} </b>
        </p>
      </div>
      <div>
        <p>Phone Number</p>
        <p>
          <b>{{ getAllDetails.patient.phone_number }} </b>
        </p>
      </div>
    </section>
    <section>
      <p>Delivery Address</p>
      <p>
        <b>{{ getAllDetails.patient.address_1 }} </b>
      </p>
    </section>
    <section>
      <p>Patients ID:</p>
      <p>
        <b>{{ getAllDetails.patient.patient_id }} </b>
      </p>
    </section>
    <section>
      <h1>Product Details</h1>
      <div>To make edits, click on '<b> Make Changes </b>' below</div>
      <div class="tele_grid _table">
        <div>Product Name</div>
        <div>Packs</div>
        <div>Category</div>
        <div>Strength</div>
        <div>Frequency</div>
        <div>Duration</div>
      </div>
      <div
        v-for="(product, i) in getAllDetails.products.prescription"
        :key="i"
        class="tele_grid _table"
      >
        <div>{{ product.product_name.name || product.other_name }}</div>
        <div>{{ product.pack }} packs</div>
        <div>{{ product.product_category }}</div>
        <div>{{ product.take }}</div>
        <div>{{ product.dosage }} (times)</div>
        <div>{{ product.duration }} (days)</div>
      </div>
    </section>

    <section class="comments-overview personal-info">
      <!-- <div class="comment">
        Comments / Instructions
        <div>
          {{ getAllDetails.products.instruction }}
        </div>
      </div> -->

      <div>
        Estimated Time of Arrival:
        <p>
          <b>{{ formatedDate }}</b>
        </p>
        Health case Practitioner Information
        <p>
          <b>{{ getAllDetails.products.practional_phone_number }}</b>
        </p>
        <p>
          <b>{{ getAllDetails.products.practinonal_email }}</b>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import store from "@/store";
import { requestHandler } from "@/configs/request-handler";
import moment from "moment";
export default {
  data() {
    return {};
  },
  computed: {
    getAllDetails() {
      console.log(store.state);
      return store.state;
    },
    formatedDate() {
      return moment(this.getAllDetails?.products?.estimated_delivery).fromNow();
    },
  },
  methods: {
    moment() {
      return moment;
    },
    async createDrugOrder() {
      try {
        // create the customer

        let response;
        if (this.getAllDetails.patient.id === undefined) {
          response = await requestHandler().post("telimedicine/patient", {
            first_name: this.getAllDetails.patient.first_name,
            last_name: this.getAllDetails.patient.last_name,
            email: this.getAllDetails.patient.email,
            phone_number: this.getAllDetails.patient.phone_number,
            patient_id: this.getAllDetails.patient.patient_id,
            address_1: this.getAllDetails.patient.address_1,
            state: this.getAllDetails.patient.state,
            city: this.getAllDetails.patient.city,
            // lga: this.getAllDetails.patient.lga,
          });
          console.log(response);
        }

        // create the order
        await requestHandler().post(`telimedicine/precription`, {
          order: {
            preciption: this.getAllDetails.products.prescription,
            practinonal_first_name:
              this.getAllDetails.products.practional_first_name,
            practinonal_last_name:
              this.getAllDetails.products.practional_last_name,
            practinonal_phone_number:
              this.getAllDetails.products.practional_phone_number,
            estimated_delivery: moment(
              this.getAllDetails.products.estimated_delivery
            ).format("YYYY-MM-DDThh:mm:ss"),
            Instruction: this.getAllDetails.products.instruction,
            patient: +this.getAllDetails.patient.id || +this.customer.id,
          },
        });
      } catch (error) {
        console.log(error, "error");
        store.commit("toast", {
          message: error.response?.data?.message || error.message,
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
.prescription-summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.tele_grid {
  margin-top: 15px;
}
.tele_grid._table {
  grid-template-columns: 2fr repeat(5, 1fr);
}
.comments-overview {
  margin-top: 40px;
}
.comment {
  border: 1px solid #dddddd;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
}
</style>
