import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import VTooltip from "v-tooltip";
import router from "./router";
import Toast from "vue-toastification";
import "vue-next-select/dist/index.css";
import 'vue-search-select/dist/VueSearchSelect.css'

import "vue-toastification/dist/index.css";
import vueNextSelect from "vue-next-select";

const app = createApp(App);

app.use(router, store, VTooltip);
app.component("v-select", vueNextSelect);
app.use(Toast);
app.mount("#app");
