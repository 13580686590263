import axios from "axios";

export const Auth = {
  currentUser: () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    return token;
  },
};

export const requestHandler = () => {
  const token = Auth.currentUser();
  axios.defaults.baseURL = "http://shop.drugstoc.com:8000/api/";
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    return axios;
  }
  return axios;
};
