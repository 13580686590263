<template>
  <h1>Schedule Information</h1>
  <div class="personal-info">
    <div class="tele-input">
      <label> ETA (Estimated Time of Arrival) </label>
      <input v-model="estimated_delivery" required type="datetime-local" />
    </div>
    <div></div>
  </div>
  <h3>Health Care Practitioner Information</h3>
  <div class="personal-info">
    <div class="tele-input">
      <label> Phone Number </label>
      <input v-model="practional_phone_number" type="number" required />
    </div>
    <div class="tele-input">
      <label> Email </label>
      <input v-model="practinonal_email" type="email" required />
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  data() {
    return {
      estimated_delivery: "",
      practional_phone_number: "",
      practinonal_last_name: "",
      practinonal_first_name: "",
      practinonal_email: "",
    };
  },
  watch: {
    $data: {
      handler() {
        store.commit("setProductsMeta", this.$data);
      },
      deep: true,
    },
  },
};
</script>
