<template>
  <div class="blank">
    <div>
      <img :src="src" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["src"],
  data() {
    return {};
  },
};
</script>

<style>
.blank {
  height: 100vh;
  background-color: #ffffff;
  width: 35%;
  min-width: 500px;
  display: flex;
  align-items: flex-end;
}

.blank img {
  width: 100%;
  height: 100%;
  margin-top: auto;
}
</style>
