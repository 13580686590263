<template>
  <h1 class="text-center">Patients Personal Information</h1>
  <div class="flex mt-25 mb-25 container gap-50">
    <div>
      <input
        type="radio"
        name="user"
        value="new"
        id="new"
        v-model="patient_type"
        @change="id = ''"
      />
      <label for="new"> New Patient </label>
    </div>
    <div>
      <input
        type="radio"
        name="user"
        value="existing"
        id="existing"
        v-model="patient_type"
      />
      <label for="existing"> Existing Patient </label>
    </div>
  </div>

  <div v-if="patient_type === 'new'">
    <div class="personal-info container">
      <div class="tele-input">
        <label> First Name </label>
        <input v-model="first_name" required />
      </div>

      <div class="tele-input">
        <label> Last Name </label>
        <input v-model="last_name" required />
      </div>
      <div class="tele-input">
        <label> Email </label>
        <input type="email" v-model="email" required />
      </div>
      <div class="tele-input">
        <label> Phone Number </label>
        <input v-model="phone_number" required type="number" />
      </div>

      <div class="tele-input">
        <label> Patient ID (optional) </label>
        <input v-model="patient_id" />
      </div>
    </div>
  </div>
  <div v-if="patient_type === 'existing'">
    <div class="personal-info container"></div>
    <h1 class="container">Patients</h1>
    <div class="container list-container">
      <div v-for="(patients, i) in allPatients" :key="i" class="list">
        <input
          type="radio"
          name="patient"
          :id="patients.first_name + i"
          :value="i"
          @change="(e) => selectCustomer(e, i)"
        />
        <label :for="patients.first_name + i">
          <b>{{ patients.first_name }} {{ patients.last_name }}</b>
          {{ patients.email }}
          {{ patients.phone_number }}
          {{ patients.address_1 }}
        </label>
      </div>
    </div>
    <div class="all_buttons container">
      <tele-button label="Next" type="button" :disabled="!pagination.next" />
      <tele-button
        label="Prev"
        type="button"
        :disabled="!pagination.previous"
      />
    </div>
  </div>

  <section v-if="patient_type === 'new'">
    <div>
      <h1 class="text-center">Delivery Address</h1>
    </div>
    <div class="personal-info container">
      <div class="tele-input">
        <label style="margin-bottom: 10px"> State </label>
        <vue-select
          v-model="state"
          @search:input="handleInput($event, i)"
          @selected="handleSelected"
          :placeholder="state || 'State'"
          :options="['Lagos', 'Ogun']"
        ></vue-select>
      </div>

      <div class="tele-input">
        <label> City/Town </label>
        <input v-model="city" required />
      </div>

      <div class="tele-input">
        <label> Address </label>
        <input v-model="address_1" required />
      </div>
    </div>
  </section>
</template>

<script>
import teleButton from "@/sub-components/tele-button";
import store from "@/store";
import { requestHandler } from "@/configs/request-handler";
import VueSelect from "vue-next-select";

export default {
  components: {
    teleButton,
    VueSelect,
  },
  data() {
    return {
      patient_type: "new",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      patient_id: "",
      address_1: "",
      state: "",
      city: "",
      id: "",
    };
  },
  methods: {
    handleInput(event) {
      this.state = event.target.value;
    },
    handleSelected(event) {
      this.state = event;
    },
    getAllPatients() {
      requestHandler()
        .get("/telimedicine/patient")
        .then((response) => {
          store.commit("setAllPatients", response.data);
        });
    },
    seachTerm(e) {
      console.log(e);
      store.commit("searchPatient", e);
    },
    selectCustomer(_e, value) {
      this.id = value;
      this.first_name = this.allPatients[value].first_name;
      this.last_name = this.allPatients[value].last_name;
      this.email = this.allPatients[value].email;
      this.phone_number = this.allPatients[value].phone_number;
      this.patient_id = this.allPatients[value].patient_id;
      this.address_1 = this.allPatients[value].address_1;
      this.state = this.allPatients[value].state;
      this.city = this.allPatients[value].city;
    },
  },
  computed: {
    allPatients() {
      return store.state.allPatients;
    },
    pagination() {
      return store.state.patientsPagination;
    },
  },
  beforeMount() {
    this.getAllPatients();
  },
  watch: {
    $data: {
      handler() {
        store.commit("patientData", this.$data);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
