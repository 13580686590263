<template>
  <section class="container-blank">
    <blank-side src="/img/Frame-login.png" />
    <div class="container">
      <div class="content">
        <h1>Login</h1>
        <p>
          New user ? <router-link to="/sign-up">Create an account</router-link>
        </p>
        <div>
          <tele-input
            label="Email Address"
            class="mt-15"
            @input="(value) => (email = value)"
          />
          <tele-input
            label="Password"
            class="mt-15"
            @input="(value) => (password = value)"
            type="password"
          />
        </div>
        <div class="controls text-center">
          <router-link class="text-center" to="/forget-password"
            >Forgot your Password ?</router-link
          >
          <tele-button
            label="Continue"
            :disabled="disabled"
            @click="logIn"
            :type="'submit'"
          />
        </div>

        <!-- <p class="text-center">or</p> -->

        <div class="text-center controls-footer">
          By clicking
          <router-link to="/sign-up"> Create account </router-link> i agree to
          all Privacy Policy and Terms of Service.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeleInput from "@/sub-components/tele-input";
import TeleButton from "@/sub-components/tele-button.vue";
import BlankSide from "@/components/blank-side.vue";
import { requestHandler } from "@/configs/request-handler";
import store from "@/store";
export default {
  components: {
    TeleInput,
    TeleButton,
    BlankSide,
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      email: "",
      password: "",
      disabled: false,
    };
  },
  methods: {
    async logIn() {
      try {
        this.disabled = true;
        const response = await requestHandler().post("/login/", {
          email: this.email,
          password: this.password,
        });
        console.log(response);
        localStorage.setItem("token", response.data.token);
        this.$router.push("/order-drugs");
      } catch (error) {
        console.log(error.response);
        store.commit("toast", {
          type: "error",
          message:
            error.response?.message ||
            error.response?.data?.message ||
            "Something went wrong",
        });
      }
      this.disabled = false;
    },
  },
};
</script>
