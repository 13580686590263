<template>
  <section class="container-blank">
    <blank-side src="/img/Frame.png" />
    <section class="container">
      <div class="content">
        <h1>Forgot your password ?</h1>
        <p>
          Recover account here by providing email to receive an authentication
          code for a password reset
        </p>
        <div class="controls">
          <tele-input :label="'Email'" :type="'email'" />
        </div>
        <div class="controls text-center">
          <tele-button label="NEXT" @click="$router.push('/verify-code')" />
        </div>

        <div class="text-center controls-footer">
          <router-link to="/login"> Login </router-link> |
          <router-link to="/sign-up"> Create an Account </router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import teleInput from "@/sub-components/tele-input.vue";
import TeleButton from "@/sub-components/tele-button.vue";
import BlankSide from "@/components/blank-side.vue";
export default {
  components: {
    teleInput,
    TeleButton,
    BlankSide,
  },
};
</script>
