<template>
  <button
    :class="
      className ? className : `tele-button-primary ${disabled && 'disabled'}`
    "
    :disabled="disabled"
    :type="type ? type : 'button'"
  >
    {{ label }}
  </button>
</template>
<script>
export default {
  props: ["label", "className", "disabled", "type"],
};
</script>

<style scoped>
button:disabled {
  background-color: rgba(204, 204, 204, 0.041);
  cursor: not-allowed;
}
</style>
