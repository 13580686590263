<template>
  <form @submit.prevent="submitSummary" id="order-drugs-form">
    <section style="margin-top: 60px">
      <div class="mt-15">
        <keep-alive>
          <component :is="components[currentComponent]"> </component>
        </keep-alive>
      </div>
    </section>
    <section id="controls" v-if="currentComponent < components.length - 1">
      <div>
        <button
          v-if="
            currentComponent > 0 && currentComponent < components.length - 1
          "
          type="button"
          class="previous-holder"
          @click="currentComponent--"
        >
          <img src="/back.svg" /> Previous
        </button>
      </div>
      <div>
        <tele-button
          label="cancle"
          @click="currentComponent = 0"
          class-name="tele-button-secondary"
          type="button"
        ></tele-button>
        <tele-button
          label="next"
          type="submit"
          :disabled="disabled"
        ></tele-button>
      </div>
    </section>
  </form>
</template>

<script>
import PersonalInformation from "@/components/personal-information";
import TeleButtons from "@/sub-components/tele-button";
import PrescriptionSummary from "@/components/prescription-summary";
import SendSuccessfull from "@/components/sent-successfully";
import ProductInformation from "@/components/product-information";
import { requestHandler } from "@/configs/request-handler";
import store from "@/store";
import moment from "moment";

export default {
  name: "App",
  components: {
    "personal-information": PersonalInformation,
    "tele-button": TeleButtons,
    "prescription-summary": PrescriptionSummary,
    "sent-successfully": SendSuccessfull,
    "product-information": ProductInformation,
  },
  computed: {
    getAllDetails() {
      return store.state;
    },
  },
  data() {
    return {
      currentComponent: 0,
      components: [
        "product-information",
        "personal-information",
        "prescription-summary",
        "sent-successfully",
      ],
      disabled: false,
    };
  },
  methods: {
    async submitSummary() {
      this.disabled = true;
      try {
        if (this.currentComponent === 2) {
          let response;
          if (!this.getAllDetails.patient.id) {
            response = await requestHandler().post("telimedicine/patient", {
              first_name: this.getAllDetails.patient.first_name,
              last_name: this.getAllDetails.patient.last_name,
              email: this.getAllDetails.patient.email,
              phone_number: this.getAllDetails.patient.phone_number,
              patient_id: this.getAllDetails.patient.patient_id,
              address_1: this.getAllDetails.patient.address_1,
              state: this.getAllDetails.patient.state,
              city: this.getAllDetails.patient.city,
              // lga: this.getAllDetails.patient.lga,
            });
          }

          const patient = this.getAllDetails.patient.id
            ? +this.getAllDetails.allPatients[this.getAllDetails.patient.id].id
            : response.data.data.id;
          await requestHandler().post("telimedicine/precription", {
            preciption: this.getAllDetails.products.prescription,
            practinonal_first_name:
              this.getAllDetails.products.practinonal_last_name,
            practinonal_last_name:
              this.getAllDetails.products.practinonal_first_name,
            practinonal_phone_number:
              this.getAllDetails.products.practinonal_phone_number,
            estimated_delivery: moment(
              this.getAllDetails.products.estimated_delivery
            ).format("YYYY-MM-DDThh:mm:ss"),
            Instruction: this.getAllDetails.products.instruction,
            patient,
          });
        }
        this.currentComponent++;
      } catch (error) {
        store.commit("toast", {
          message: error.response?.data?.message || "An error occured",
          type: "error",
        });
      }
      this.disabled = false;
    },
    // async createPatient() {
    //   // create the customer
    //   if (this.getAllDetails.patient.id === undefined) {
    //     const response = await requestHandler().post("telimedicine/patient", {
    //       first_name: this.getAllDetails.patient.first_name,
    //       last_name: this.getAllDetails.patient.last_name,
    //       email: this.getAllDetails.patient.email,
    //       phone_number: this.getAllDetails.patient.phone_number,
    //       patient_id: this.getAllDetails.patient.patient_id,
    //       address_1: this.getAllDetails.patient.address_1,
    //       state: this.getAllDetails.patient.state,
    //       city: this.getAllDetails.patient.city,
    //       // lga: this.getAllDetails.patient.lga,
    //     });
    //     console.log(response, "patient created successfully");
    //     return response.data;
    //   }
    // },
  },
};
</script>
